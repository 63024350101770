<template>
  <div>
    {{ nbQuestions }}
  </div>
</template>
  
  <script>
  import { mapGetters } from 'vuex';
  export default {
      props: {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      computed: {
          ...mapGetters({
            questions: 'sanitaire/questions'
          }),
          nbQuestions(){
            return [...this.questions].filter(item  => item.section === this.object.uid).length
          }
      }
  }
  </script>
  
  <style>
  
  </style>